import Masonry from "react-masonry-css";

const ContentLayoutCSSGrid = ({ children }) => {
  return (
    <div className="grid content-container" style={{ gridTemplateColumns: " repeat(auto-fill, minmax(25%, 1fr))" }}>
      {children}
    </div>
  );
};

const ContentLayoutCSSBlock = ({ children }) => {
  return (
    <div className="container content-container" style={{}}>
      {children}
    </div>
  );
};

const ContentLayoutCardGrid = ({ children, stylingCustomClasses = "" }) => {
  return (
    <div className="container">
      <div className={["row uc-card-grid mx-1 my-3", stylingCustomClasses.trim()].join(" ")}>
        {children
          ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
          ?.map((card) => {
            return (
              <div key={card.key} className="d-flex p-0">
                {card}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const ContentLayoutCardGroup = ({ children, ...props }) => {
  const { categoryList = [] } = props;
  if (categoryList.length > 0) {
    return categoryList.map((category) => {
      const childList = [];
      children.map((child) => {
        if (category === child.props?.el?.cardCategory) {
          return childList.push(child);
        }
        return null;
      });
      return (
        <>
          <h3 className="fw-bold">{category}</h3>
          <div className="grid" style={{ gridTemplateColumns: " repeat(auto-fill, minmax(200px, 1fr))" }}>
            {childList}
          </div>
        </>
      );
    });
  } else {
    return (
      <div className="grid" style={{ gridTemplateColumns: " repeat(auto-fill, minmax(200px, 1fr))" }}>
        {children}
      </div>
    );
  }
};

const ContentLayoutCardMasontry = ({
  children,
  breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  },
}) => {
  return (
    <div className="container-fluid px-3">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="sw-masonry-grid mt-3"
        columnClassName="sw-masonry-grid_column"
      >
        {children
          ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
          ?.map((card) => {
            return (
              <div key={card.key} className="brick">
                {card}
              </div>
            );
          })}
      </Masonry>
    </div>
  );
};

export {
  ContentLayoutCSSBlock,
  ContentLayoutCSSGrid,
  ContentLayoutCardGrid,
  ContentLayoutCardGroup,
  ContentLayoutCardMasontry,
};
