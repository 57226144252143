import { useContentContext } from "../../../contexts/ContentContext";
import { Helmet } from "react-helmet";

const useSEO = () => {
  const pageData = useContentContext();
  const formattedSEO = pageData?.formattedSEO;

  const SEO = () => {
    return (
      <>
        <Helmet>{formattedSEO?.title && <title>{formattedSEO.title}</title>}</Helmet>
        {formattedSEO?.meta?.map((meta) => (
          <Helmet>
            <meta name={meta.name} content={meta.content} />
          </Helmet>
        ))}
      </>
    );
  };

  return { SEO };
};

export { useSEO };
