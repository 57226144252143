import { addShippingAddressToOrderFulfillment } from "../../../actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { getErrorMessage } from "../../../utils";
import { useElementContext } from "../../../contexts";

const TradeOrderAddress = ({ orderID, orderFulfillment, showSummary }) => {
  const {
    CommonModule: { BillingAddress, Modal },
  } = useElementContext();
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState(
    orderFulfillment?.orderFulfillment_shippingAddress_streetAddress
      ? `${orderFulfillment?.orderFulfillment_shippingAddress_streetAddress}, ${orderFulfillment?.orderFulfillment_shippingAddress_street2Address}, ${orderFulfillment?.orderFulfillment_shippingAddress_city}, ${orderFulfillment?.orderFulfillment_shippingAddress_postalCode}`
      : `Update your delivery address`,
  );
  const dispatch = useDispatch();

  const saveAddressOnOrder = (values) => {
    dispatch(
      addShippingAddressToOrderFulfillment({
        params: {
          orderID: orderID,
          fulfillmentID: orderFulfillment.orderFulfillment_orderFulfillmentID,
          accountAddressName: "",
          city: values?.city,
          countryCode: values?.countryCode,
          emailAddress: values?.emailAddress,
          locality: values?.locality,
          name: values?.name,
          phoneNumber: values?.phoneNumber,
          postalCode: values?.postalCode,
          saveAddress: values?.saveAddress,
          stateCode: values?.stateCode,
          street2Address: values?.street2Address,
          streetAddress: values?.streetAddress,
        },
      }),
    ).then((response) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
        toast.error(getErrorMessage(response.success().errors));
      else if (response.isSuccess()) {
        setAddress(`${values?.streetAddress}, ${values?.street2Address}, ${values?.city}, ${values?.postalCode}`);
        toast.success("Address has been saved successfully for order.");
        setShowModal(false);
      }
    });
  };

  return (
    <>
      <h3 className="h5 mb-0 me-2 font-weight-bold">
        Deliver to:{" "}
        <div className={showSummary ? "mt-2" : "alert alertbox mt-2"}>
          <p className="small fw-normal"> {address}</p>{" "}
        </div>
      </h3>

      {!showSummary && <i className="bi bi-pencil linkit" onClick={() => setShowModal(true)}></i>}

      {showModal && (
        <Modal show={showModal} setShow={setShowModal} title="Address" size="xMedium">
          <BillingAddress
            saveAddressOnAccount={false}
            onSave={(values) => {
              saveAddressOnOrder(values);
            }}
            onCancel={(values) => {
              setShowModal(false);
            }}
            existingAddress={{
              name: orderFulfillment?.orderFulfillment_shippingAddress_name,
              countryCode: orderFulfillment?.orderFulfillment_shippingAddress_countryCode,
              stateCode: orderFulfillment?.orderFulfillment_shippingAddress_stateCode,
              postalCode: orderFulfillment?.orderFulfillment_shippingAddress_postalCode,
              locality: orderFulfillment?.orderFulfillment_shippingAddress_locality,
              city: orderFulfillment?.orderFulfillment_shippingAddress_city,
              streetAddress: orderFulfillment?.orderFulfillment_shippingAddress_streetAddress,
              street2Address: orderFulfillment?.orderFulfillment_shippingAddress_street2Address,
              phoneNumber: orderFulfillment?.orderFulfillment_shippingAddress_phoneNumber,
              emailAddress: orderFulfillment?.orderFulfillment_shippingAddress_emailAddress,
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default TradeOrderAddress;
