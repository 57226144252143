import React, { useEffect, useState, useRef } from "react";
import { useFormatCurrency } from "../../hooks";
import { toast } from "react-toastify";
import { useDebounce } from "react-use";
import { getErrorMessage } from "../../utils";
import { axios, getSdkURL } from "../../services";
import { useElementContext } from "../../contexts";

const FilterOption = ({ orderFilter, filter, setFilter }) => {
  const [selectedBrands, setSelectedBrands] = useState(filter.brandID.split(",").filter((item) => item !== ""));
  const [selectedCategories, setSelectedCategories] = useState(
    filter.categoryID.split(",").filter((item) => item !== ""),
  );

  const brandMounted = useRef();
  useEffect(() => {
    if (brandMounted.current) {
      setFilter({
        brandID: selectedBrands.join(","),
      });
    } else {
      brandMounted.current = true;
    }
  }, [selectedBrands, setFilter]);

  const categoryMounted = useRef();
  useEffect(() => {
    if (categoryMounted.current) {
      setFilter({
        categoryID: selectedCategories.join(","),
      });
    } else {
      categoryMounted.current = true;
    }
  }, [selectedCategories, setFilter]);

  /**
   *
   * @param {*} primaryID - primaryID used with checkbox selections
   * @param {*} stateVariable - current state variable
   * @param {*} setStateVariable - set method for state
   * @param {*} itemToBeAdded - item added or removed
   */
  const handleSelection = (primaryID, stateVariable, setStateVariable, itemToBeAdded = false) => {
    //check if item is already added
    const itemIsAdded = stateVariable.filter((item) => item === primaryID).length > 0;
    //if item is to be added and it's not already added
    if (itemToBeAdded && !itemIsAdded) {
      setStateVariable([...stateVariable, primaryID]);
    } else if (!itemToBeAdded && itemIsAdded) {
      //if item is to be removed and is already added
      setStateVariable(stateVariable.filter((item) => item !== primaryID));
    }
  };

  return (
    <>
      {"brands" in orderFilter && orderFilter.brands.length > 0 && (
        <div className="col-6">
          <h6>Show Brands</h6>
          <div className="col-12 row">
            {orderFilter.brands.map((brand, index) => {
              return (
                <div className="col-4" key={`${brand.brandID}_${index}`}>
                  <label htmlFor={brand.brandID} className="form-check-label">
                    <input
                      type="checkbox"
                      id={brand.brandID}
                      className="form-check-input mx-1"
                      defaultChecked={selectedBrands.includes(brand.brandID)}
                      onClick={(e) => {
                        handleSelection(brand.brandID, selectedBrands, setSelectedBrands, e.target.checked);
                      }}
                    />
                    {brand.brandName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {"categories" in orderFilter && orderFilter.categories.length > 0 && (
        <div className="col-6">
          <h6>Show Category</h6>
          <div className="col-12 row">
            {orderFilter.categories.map((category, index) => {
              return (
                <div className="col-4" key={`${category.categoryID}_${index}`}>
                  <label htmlFor="Category1" className="form-check-label">
                    <input
                      type="checkbox"
                      id="Category1"
                      className="form-check-input mx-1"
                      defaultChecked={selectedCategories.includes(category.categoryID)}
                      onClick={(e) => {
                        handleSelection(
                          category.categoryID,
                          selectedCategories,
                          setSelectedCategories,
                          e.target.checked,
                        );
                      }}
                    />
                    {category.categoryName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const LineOrderItemQuantity = ({
  orderID,
  orderItemID,
  price,
  quantity,
  setOrderItemQuantity,
  removeOrderItemFromList,
  getOrderInfo,
  showSummary,
}) => {
  const [formatCurrency] = useFormatCurrency({});
  const [localQuantity, setLocalQuantity] = useState(quantity);

  const handleChange = (value) => {
    //do not entertain change when showing summary
    if (showSummary) return;

    setLocalQuantity(parseInt(value) > 0 ? value : 1);
  };

  const saveQuantity = () => {
    //show loader
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/updateOrderItemQuantity`,
      data: {
        orderID,
        orderItem: {
          orderItemID: orderItemID,
          quantity: localQuantity,
        },
      },
    })
      .then((response) => {
        if (response?.status === 200 && response?.data?.failureActions.length === 0) {
          toast.success("Item quantity updated successfully");
          setOrderItemQuantity(localQuantity);
          getOrderInfo();
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
        }
      })
      .finally(() => {
        //hide loader
      });
  };

  const removeOrderItem = (orderID, orderItemID) => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/removeOrderItem`,
      data: {
        orderID,
        orderItemID,
        returnJSONObjects: "cart",
      },
    }).then((response) => {
      if (response?.status === 200 && response?.data?.failureActions.length === 0) {
        toast.success("Item removed successfully");
        removeOrderItemFromList();
      } else toast.error(getErrorMessage(response?.data?.failureActions));
    });
  };

  const cancelQuantity = () => {
    handleChange(quantity);
  };

  return (
    <>
      <td>
        <input
          name="qty"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={localQuantity}
          type="number"
          className="form-control ps-3"
          style={{ width: "100px", borderRadius: "30px" }}
        />
        {parseInt(localQuantity) !== parseInt(quantity) && (
          <span>
            <span
              className="text-primary small text-underline linkit small pr-2"
              title="Save"
              onClick={() => saveQuantity()}
            >
              Save
            </span>
            <span
              className="text-primary small float-right text-underline linkit"
              title="Cancel"
              onClick={() => cancelQuantity()}
            >
              Cancel
            </span>
          </span>
        )}
      </td>
      <td className="text-right">
        <b>{formatCurrency(price * localQuantity)}</b>
        {!showSummary && (
          <>
            <br />
            <span
              className="text-primary small text-underline btn p-0"
              onClick={(e) => {
                const confirmed = window.confirm("Are you sure you want to delete this item?");

                if (confirmed) {
                  removeOrderItem(orderID, orderItemID);
                }
              }}
            >
              Remove
            </span>{" "}
          </>
        )}
      </td>
    </>
  );
};

const LineOrderItem = ({ orderItem, index, orderID, removeOrderItemFromList, getOrderInfo, showSummary }) => {
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();
  const [formatCurrency] = useFormatCurrency({});

  const [orderItemQuantity, setOrderItemQuantity] = useState(orderItem.quantity);

  const displayMinSetting = (orderItem) => {
    const minSettingValue = orderItem.skuOrderMinimumQuantity ?? "";

    return minSettingValue !== "" ? minSettingValue + " Unit" : "";
  };

  const displayLeadTime = (orderItem) => {
    const allowBuyNow = orderItem.skuAllowBuyNowFlag ?? "";
    return allowBuyNow !== "" && allowBuyNow ? "In Stock" : "Out Of Stock";
  };

  const displayCategoriesBrand = (orderItem) => {
    const categoryName = orderItem.sku_product_categories_categoryName ?? "";
    const brandName = orderItem?.sku_product_brand_brandName ?? "";
    const commaSeparatedList = categoryName + (categoryName !== "" && brandName !== "" ? ", " : "") + brandName;

    return commaSeparatedList;
  };

  return (
    <React.Fragment key={index}>
      <tr className="py-2">
        <td className="ps-0">
          <SimpleImage
            src={orderItem?.imagePath}
            className="order-table-img-fluid"
            alt={orderItem?.sku_product_productName}
          />
        </td>
        <td>
          <span className="font-weight-bold d-block">{orderItem.sku_product_productName}</span>
          <span className="small">
            SKU: {orderItem?.sku_skuCode} {orderItem.sku_calculatedSkuDefinition}
          </span>
        </td>
        <td> {displayCategoriesBrand(orderItem)} </td>
        <td>{orderItem.sku_product_vendor_vendorName ?? ""}</td>
        <td>{displayLeadTime(orderItem)}</td>
        <td>{displayMinSetting(orderItem)}</td>
        <td>{formatCurrency(orderItem.price)}</td>

        <LineOrderItemQuantity
          getOrderInfo={getOrderInfo}
          removeOrderItemFromList={removeOrderItemFromList}
          orderItemID={orderItem.orderItemID}
          orderID={orderID}
          quantity={orderItemQuantity}
          setOrderItemQuantity={setOrderItemQuantity}
          price={orderItem.price}
          key={"lineOrderItem" + index}
          showSummary={showSummary}
        />
      </tr>
    </React.Fragment>
  );
};

const DraftOrderTab = ({
  showFilters,
  orderItems,
  orderTotal,
  orderFilters,
  orderID,
  filter,
  setFilter,
  getOrderInfo,
  showSummary,
}) => {
  const [formatCurrency] = useFormatCurrency({});
  const [localOrderItems, setLocalOrderItems] = useState(orderItems);
  const [keyword, setKeyword] = useState(filter.keyword ?? "");

  const keywordMounted = useRef();
  useDebounce(
    () => {
      if (keywordMounted.current) {
        setFilter({
          keyword: keyword,
        });
      } else {
        keywordMounted.current = true;
      }
    },
    800,
    [keyword],
  );

  useEffect(() => {
    setLocalOrderItems(orderItems);
  }, [orderItems]);

  const removeOrderItemFromList = (orderItem) => {
    const updatedItems = localOrderItems.filter((item, i) => item.orderItemID !== orderItem.orderItemID);
    setLocalOrderItems(updatedItems);
    getOrderInfo();
  };

  return (
    <>
      {showFilters && (
        <div className="bg-light p-4 my-3 row">
          <div className="col-3">
            <label className="label">Search Order</label>
            <input
              name="searchOrder"
              type="text"
              className="form-control"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
          <div className="col-9 row">
            {orderFilters?.length > 0 &&
              orderFilters?.map((orderFilter, index) => {
                return (
                  <React.Fragment key={"filterDOT" + index}>
                    <FilterOption orderFilter={orderFilter} filter={filter} setFilter={setFilter} />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      )}
      <div className="table-responsive">
        <table className="table mt-3 mb-0">
          <thead>
            <tr>
              <th style={{ width: "75px", minWidth: "75px" }}></th>
              <th style={{ minWidth: "250px" }}></th>
              <th style={{ minWidth: "160px" }}>Category & Brand</th>
              <th style={{ minWidth: "100px" }}>Supplier</th>
              <th style={{ minWidth: "100px" }}>Lead Time</th>
              <th style={{ minWidth: "100px" }}>Min Order</th>
              <th style={{ minWidth: "100px" }}>Price</th>
              <th style={{ minWidth: "100px" }}>Qty</th>
              <th style={{ minWidth: "75px" }}></th>
            </tr>
          </thead>
          <tbody>
            {localOrderItems &&
              localOrderItems.map((orderItem, index) => {
                return (
                  <LineOrderItem
                    getOrderInfo={getOrderInfo}
                    removeOrderItemFromList={() => removeOrderItemFromList(orderItem)}
                    orderItem={orderItem}
                    orderID={orderID}
                    index={index}
                    key={"lineOi" + index}
                    showSummary={showSummary}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="bg-light text-end p-2 my-2 font-weight-bold">
        <span className="me-3">Subtotal:</span> {formatCurrency(orderTotal)}
      </div>
    </>
  );
};

export default DraftOrderTab;
