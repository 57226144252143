import React from "react";
import { useUtilities } from "../../hooks/useUtilities";

const HTMLcontent = (props) => {
  const { stylingCustomClasses = "", contentBody = "", systemCode, bootStrapLayoutClass } = props;
  let { eventHandlerForWSIWYG } = useUtilities();

  return (
    <div
      className={["bodyContent htmlContent", stylingCustomClasses, systemCode, bootStrapLayoutClass].join(" ")}
      onClick={eventHandlerForWSIWYG}
      dangerouslySetInnerHTML={{ __html: contentBody }}
    />
  );
};

export { HTMLcontent };
