const ContentColumns = (props) => {
  let { children = [], contentElementName, maxColumn, style } = props;
  let iters = 1;
  if (!maxColumn || maxColumn === 0) {
    maxColumn = 4;
  }
  if (children.length && maxColumn && maxColumn !== 0) {
    iters = Math.ceil(children.length / maxColumn);
  }
  if (!children) {
    return null;
  }
  return (
    <section className="content-columns-sec cetColumns container">
      {contentElementName?.trim()?.length > 0 && (
        <header className="section-title pb-4">
          <h2>{contentElementName}</h2>
        </header>
      )}
      <CallColumns iters={iters} children={children} maxColumn={maxColumn} style={style} />
    </section>
  );
};

const CallColumns = ({ iters, children, maxColumn, style }) => {
  let valDict = [];
  let prevCount = -1;
  for (var iterNo = 0; iterNo < iters; iterNo++) {
    valDict.push(iterNo);
  }

  return (
    <div className="wrapper-container px-0">
      {valDict.map((nos, i) => {
        return (
          <div key={i} className="row justify-content-start">
            {children?.map((el, idx) => {
              if (idx > prevCount && idx < (nos + 1) * maxColumn) {
                prevCount = idx;
                return (
                  <div
                    key={idx}
                    className={`cetColumnsItem pr-4-lg px-0 `}
                    style={style === "info" ? { width: "25%" } : { width: `${100 / maxColumn}%` }}
                  >
                    {el}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      })}
    </div>
  );
};
export { ContentColumns };
