import { useFormatCurrency } from "../../hooks";
import { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { useDebounce } from "react-use";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utils";
import { axios, getSdkURL } from "../../services";
import { useElementContext, useServiceContext } from "../../contexts";

const ProductList = ({ products, selectedSkus, setSelectedSkus }) => {
  const {
    CommonModule: { DraftOrderProductCard },
  } = useElementContext();
  return products.map((product, index) => {
    return (
      <DraftOrderProductCard
        key={index}
        product={product}
        selectedSkus={selectedSkus}
        setSelectedSkus={setSelectedSkus}
      />
    );
  });
};

const AddProductsTab = ({ orderID, getOrderInfo }) => {
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllFetched, setAllFetched] = useState(false);

  const [searchstring, setSearchstring] = useState("");
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [formatCurrency] = useFormatCurrency({});
  const loadMoreItemRef = useRef();
  const observer = useRef();
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  useEffect(() => {
    if (isFetching || isAllFetched || window.innerWidth < 768) return;
    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page of products
      }
    };
    // Initialize IntersectionObserver when component mounts
    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    // Attach observer to product container element
    if (loadMoreItemRef.current) {
      observer.current.observe(loadMoreItemRef.current);
    }

    return () => {
      // Remove observer when component unmounts
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [isAllFetched, isFetching]);

  useDebounce(
    () => {
      const source = axios.CancelToken.source();
      setIsFetching(true);

      productService
        .search({ searchTerm: searchstring, pageFrom: currentPage + 1, pageSize: 20 }, "product", source)
        .then((data) => {
          if ((data.products || []).length > 0) {
            setProducts((prevProduct) =>
              prevProduct.length === 0 ? data.products : [...prevProduct, ...data.products],
            );
          } else {
            setAllFetched(true);
          }
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    800,
    [currentPage, searchstring],
  );

  const SelectedProductRow = ({ skus, handleSelectedSkuRemove, updateSelectSkuQuantity }) => {
    const [skuList, setSkuList] = useState(skus);

    const handleSkuListChange = (skuList) => {
      setSkuList(skuList);
      updateSelectSkuQuantity(updateSelectSkuQuantity);
    };

    return skuList.map((sku, index) => (
      <tr key={index} className="row align-items-center pb-2 border-bottom">
        <td className="col-1">
          <i
            className="bi bi-trash3 text-primary"
            onClick={(e) => {
              if (window.confirm("Do you want to remove this item ?")) {
                handleSelectedSkuRemove(sku);
              }
            }}
          ></i>
        </td>
        <td className="col-3">{sku.skuCode}</td>
        <td className="col-3">{formatCurrency(sku.price)} | per L/M</td>
        <td className="col-3">
          <input
            type="number"
            value={sku.quantity}
            onChange={(e) =>
              handleSkuListChange(
                skuList.map((item) => {
                  if (item.skuID === sku.skuID) {
                    item.quantity = parseInt(e.target.value) > 0 ? e.target.value : 1;
                  }
                  return item;
                }),
              )
            }
            className="form-control"
            name="qty"
          />
        </td>
        <td className="col-2">{formatCurrency(sku.price * sku.quantity)}</td>
      </tr>
    ));
  };

  const handleSelectedSkuRemove = (productName, sku) => {
    setSelectedSkus((selectedSkus) =>
      selectedSkus.map((item) => {
        if (item.productName === productName) {
          item.skus = item.skus.filter((item) => {
            return item["skuID"] !== sku.skuID;
          });
        }
        return item;
      }),
    );
  };

  const updateSelectSkuQuantity = (productName, skuList) => {
    setSelectedSkus((selectedSkus) =>
      selectedSkus.map((item) => {
        if (item.productName === productName) {
          item.skus = skuList;
        }
        return item;
      }),
    );
  };

  const addOrderItems = () => {
    const skuIDs = selectedSkus.map((item) => item.skus.map((sku) => sku.skuID).join(",")).join(",");
    const quantities = selectedSkus.map((item) => item.skus.map((sku) => sku.quantity).join(",")).join(",");

    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItems`,
      data: {
        orderID,
        skuIDs,
        quantities,
      },
    }).then((response) => {
      if (response?.status === 200 && response?.data?.failureActions.length === 0) {
        setSelectedSkus([]);
        getOrderInfo();
      } else toast.error(getErrorMessage(response?.data?.failureActions));
    });
  };

  return (
    <>
      <div className="row justify-content-between py-2 my-2">
        <div className="col-12 col-sm-6 col-xl-4">
          <div className="input-group input-group-lg rounded-pill">
            <input
              className="form-control appended-form-control rounded-pill"
              type="text"
              placeholder="Search for products to add to your order"
              required=""
              onChange={(e) => {
                setSearchstring(e.target.value);
                setProducts([]);
                setCurrentPage(0);
                setAllFetched(false);
                setIsFetching(true);
                observer.current?.disconnect();
              }}
            />
            <button className="btn btn-link px-2" type="button">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-8 text-right">
          <button className="btn btn-primary" disabled={!selectedSkus.length} onClick={(e) => addOrderItems()}>
            Add To Order
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          {products.length > 0 && (
            <ProductList products={products} setSelectedSkus={setSelectedSkus} selectedSkus={selectedSkus} />
          )}

          {products.length === 0 && !isFetching && (
            <div className="card p-3 my-2">
              <h5 className="text-danger">No Data Found</h5>
            </div>
          )}

          {isFetching && (
            <div className="card p-3 my-2">
              <h5 className="text-center">Loading....</h5>
            </div>
          )}

          <div ref={loadMoreItemRef}>{/* blank div to tap when doing infinite scroll */}</div>
        </div>

        <div className="col-md-6 col-lg-8 table-responsive">
          <table className="w-100">
            <thead></thead>
            <tbody>
              {selectedSkus.map(({ productName, skus }, parentIndex) => {
                if (!skus.length) {
                  return null;
                }
                return (
                  <Fragment key={parentIndex}>
                    <tr>
                      <td>
                        <h5 className="mx-1 my-2">{productName}</h5>
                      </td>
                    </tr>

                    <SelectedProductRow
                      skus={skus}
                      handleSelectedSkuRemove={(sku) => handleSelectedSkuRemove(productName, sku)}
                      updateSelectSkuQuantity={updateSelectSkuQuantity}
                    />
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddProductsTab;
