import { useElementContext } from "../../contexts";

export interface CallToActionBtnProps {
  urlTarget: string;
  linkUrl: string;
  linkLabel: string;
}

const CallToActionBtn = ({ urlTarget, linkLabel, linkUrl }: CallToActionBtnProps) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();

  return (
    <>
      {linkUrl ? (
        <ExternalLink
          linkUrl={linkUrl}
          className="btn btn-primary text-white"
          target={urlTarget}
          {...(urlTarget === "_blank" ? { rel: "noreferrer noopener" } : {})}
        >
          {linkLabel}
        </ExternalLink>
      ) : (
        <></>
      )}
    </>
  );
};

export default CallToActionBtn;
