import { Link } from "react-router-dom";
import { useAccountOverview } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";

const OverviewRecentOrders = () => {
  const { t } = useTranslation();
  const { orders } = useAccountOverview({});
  const {
    CommonModule: { OverviewOrderList },
  } = useElementContext();

  return (
    <div className="my-3">
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h4>{t("frontend.order.recent_order")}</h4>
        <Link className="btn btn-primary" to="/my-account/orders">
          {t("frontend.order.view_orders")}
        </Link>
      </div>

      <OverviewOrderList orders={orders} />
    </div>
  );
};

export default OverviewRecentOrders;
