import { FormEvent, PropsWithChildren, useCallback, useState } from "react";
import { DynamicFormProps, Payload } from "../DynamicForm/interface";
import { useElementContext } from "../../contexts";

export interface SubscribeFormProps {
  actionButtonText: string;
  bootStrapLayoutClass: string;
  innerElements: [DynamicFormProps];
  successMessage: string;
  title: string;
}

const SubscribeForm = ({
  title,
  innerElements,
  actionButtonText,
  successMessage,
  bootStrapLayoutClass,
}: PropsWithChildren<SubscribeFormProps>) => {
  const {
    CommonModule: { DynamicForm },
  } = useElementContext();
  const [defaultPayload, setDefaultPayload] = useState<Payload | null>(null);

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const ucFormProps = innerElements[0];
      const { value } = e.currentTarget["email"];
      const emailPayload = ucFormProps.formQuestions.reduce<Payload>((acc, cur) => {
        if (cur.inputType === "email") {
          acc[cur.attributeCode] = value;
        }
        return acc;
      }, {});

      setDefaultPayload(emailPayload);
    },
    [innerElements],
  );

  return (
    <div className={`p-4 cetSubscribeForm mx-auto ${bootStrapLayoutClass}`}>
      {defaultPayload ? (
        <DynamicForm
          {...innerElements[0]}
          formHeading={title}
          successMessage={successMessage}
          actionButtonText={actionButtonText}
          defaultPayload={defaultPayload}
        />
      ) : (
        <>
          <h2 className="text-center pb-4">{title}</h2>
          <form className="d-flex cetSubscribeForm__form" onSubmit={onSubmit}>
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter your email to subscribe to our newsletter"
              aria-label="email"
            />
            <div className="input-group-append">
              <button className="btn btn-sm btn-primary" type="submit">
                {actionButtonText}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SubscribeForm;
