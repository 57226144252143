import { addToCart } from "../../../../actions";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductCardActionsProps } from "./ProductCardActions.componentModel";
import { ProductCardActionsModel } from "./ProductCardActions.componentModel";
import { isAuthenticated } from "../../../../utils";

export function useComponentModel(props: ProductCardActionsProps) {
  const dispatch = useDispatch();
  const [isFetching, setFetching] = useState(false);
  const [itemCount, setItemCount] = useState(1);
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);

  const componentData = new ProductCardActionsModel({
    props,
    sitePurpose: siteSetting.sitePurpose,
    isAuthenticatedUser: isAuthenticated(),
    isFetching,
    itemCount,
  });

  const onAddToCart = useCallback(
    async (itemCount: number) => {
      setFetching(true);
      await dispatch(addToCart(props.sku.skuID, itemCount) as any);
      setFetching(false);
      setItemCount(1);
    },
    [dispatch, props.sku.skuID],
  );

  const setQuantity = (n: number) => {
    setItemCount(n);
  };

  return { componentData, componentService: { onAddToCart, setQuantity } };
}
