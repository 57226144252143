import { useCallback, useEffect } from "react";
import { ProductFormView } from "./ProductForm.componentView";
import { ProductFormProps } from "./ProductForm.componentModel";
import { useComponentModel } from "./ProductForm.componentService";
import { Quote } from "../../../../interface/Quote";

const ProductForm = (props: ProductFormProps) => {
  const { componentData, componentService } = useComponentModel(props);
  const { getAllCartOrQuotes } = componentService;
  useEffect(() => {
    if (componentData.authenticationRequiredForAddToCart) return;
    getAllCartOrQuotes();
  }, [componentData.authenticationRequiredForAddToCart, getAllCartOrQuotes]);
  const skuConfiguration = props.skuConfiguration;

  const addToCartOrQuote = useCallback(
    (quote?: Quote) => {
      if (componentData.isLoading) return;
      componentService.addToQuote(
        componentData.sku.skuID || componentData.sku.sku_skuID,
        componentData.itemCount,
        quote,
        skuConfiguration,
      );
    },
    [
      componentData.isLoading,
      componentData.itemCount,
      componentData.sku.skuID,
      componentData.sku.sku_skuID,
      componentService,
      skuConfiguration,
    ],
  );

  return <ProductFormView viewData={componentData} {...componentService} addToCartOrQuote={addToCartOrQuote} />;
};

export { ProductForm, type ProductFormProps };
