import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useListing } from "../../../../hooks";
import { SearchListingProps, SearchListingModel } from "./SearchListing.componentModel";
import { LISTING } from "../../productListing.moduleModels";

export const useComponentData = (props: SearchListingProps) => {
  const defaultListingConfig = useSelector((state: any) => state.configuration.defaultListingConfig);
  const [preFilter] = useState(props.initPreFilter);
  const searchConfig = JSON.parse(props?.listingConfig) || defaultListingConfig;
  const searchListingData = useListing(preFilter, searchConfig);
  const [viewMode, setViewMode] = useState(searchConfig.viewMode || LISTING);

  const componentData = useMemo(
    () => new SearchListingModel({ props, searchConfig, searchListingData, viewMode }),
    [props, searchConfig, searchListingData, viewMode],
  );

  return {
    componentData,
    componentService: {
      setViewMode,
    },
  };
};
