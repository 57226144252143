import { useBrandContext } from "../../../pages/Brand/BrandContext";
import { useElementContext } from "../../../contexts";

export interface BrandRangeListProps {
  rangeTilesSettings: "showOnlyDirectChild" | "showHierarchy";
}

const BrandRangeList = ({ rangeTilesSettings }: BrandRangeListProps) => {
  const { ProductModule } = useElementContext();
  const { rangeList } = useBrandContext();
  if (!rangeList) return null;

  return (
    <ProductModule.CoreComponents.ProductTypeList
      {...rangeList}
      showOnlyDirectChild={rangeTilesSettings === "showOnlyDirectChild"}
    />
  );
};

export default BrandRangeList;
