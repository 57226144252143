import { useGetEntity } from "../../hooks/useAPI";
import { useSelector } from "react-redux";
import { getProductTypeRoute } from "../../selectors/configurationSelectors";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUtilities } from "../useUtilities";

const useProductType = () => {
  const { id } = useParams();
  let { pathname } = useLocation();
  const { augmentProductType } = useUtilities();
  const productTypeRoute = useSelector(getProductTypeRoute);
  const productTypeBase = useSelector((state) => state.configuration.filtering.productTypeBase);
  const [productTypeRequest, setProductTypeRequest] = useGetEntity();
  const [productTypeListRequest, setProductTypeListRequest] = useGetEntity();
  const [lastUrlTitle, setLastUrlTitle] = useState(id);
  let isError = false;
  let errorMessage = "";
  const crumbCalculator = () => {
    if (process.env.REACT_APP_DELTA_STORE_URL) {
      const crumbs = productTypeData?.ancestors?.slice(1).map((ancestor, idx) => ({
        title: ancestor.productTypeName,
        urlTitle:
          idx > 1
            ? productTypeData?.ancestors
                .slice(1, idx + 2)
                .map((c) => c.urlTitle)
                .join("/")
            : "",
      }));
      return crumbs;
    } else {
      return productTypeRequest.data?.ancestors
        ?.map((crumb) => {
          return { title: crumb.productTypeName, urlTitle: crumb.urlTitle };
        })
        .filter((crumb) => crumb.urlTitle !== productTypeBase)
        .filter((crumb) => crumb.urlTitle !== id)
        .map((crumb) => {
          return { ...crumb, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` };
        });
    }
  };
  useEffect(() => {
    const urlTitle = pathname.split("/").reverse()?.at(0);
    const hasData = !!productTypeListRequest.data.filter((pt) => pt.urlTitle === urlTitle).length;
    if (!hasData && productTypeListRequest.data.length > 0) {
      setProductTypeRequest({
        ...productTypeRequest,
        data: {},
        isFetching: false,
        isLoaded: false,
        params: { urlTitle },
        makeRequest: true,
      });
      setProductTypeListRequest({
        ...productTypeListRequest,
        data: [],
        isFetching: false,
        isLoaded: false,
        params: {},
        makeRequest: false,
      });
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (lastUrlTitle !== id) {
    setLastUrlTitle(id);
    setProductTypeRequest({
      ...productTypeRequest,
      data: {},
      isFetching: false,
      isLoaded: false,
      params: { urlTitle: id },
      makeRequest: true,
    });
  }

  if (!productTypeRequest.isFetching && !productTypeRequest.isLoaded) {
    setProductTypeRequest({
      ...productTypeRequest,
      isFetching: true,
      isLoaded: false,
      entity: "productType",
      params: { urlTitle: id },
      makeRequest: true,
    });
  }
  if (
    !productTypeRequest.isFetching &&
    productTypeRequest.isLoaded &&
    Object.keys(productTypeRequest.data).length === 0
  ) {
    isError = true;
    //throw new Error(t('frontend.core.error.title'))
  }
  if (!productTypeListRequest.isFetching && !productTypeListRequest.isLoaded && productTypeRequest.isLoaded) {
    setProductTypeListRequest({
      ...productTypeListRequest,
      data: [],
      isFetching: true,
      isLoaded: false,
      entity: "productType",
      params: {
        "f:productTypeIDPath:like": `%${productTypeRequest.data.productTypeID}%`,
        "p:show": 250,
        includeSettingsInList: true,
      },
      makeRequest: true,
    });
  }
  const productTypeData = process.env.REACT_APP_DELTA_STORE_URL
    ? productTypeRequest.data
    : augmentProductType(id, productTypeListRequest.data);

  return {
    productTypeRequest,
    productTypeData,
    productTypeListRequest,
    crumbCalculator,
    productTypeRoute,
    isError,
    errorMessage,
  };
};

const formatProductTypeCachedResponse = (data) => ({
  ...data,
  childProductTypes: data.children.map((child) => ({
    ...child,
    urlTitle: child.productTypeUrlTitlePath.replace("merchandise/", ""),
  })),
});

export { useProductType, formatProductTypeCachedResponse };
