import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));
const ContentGrid = (props) => {
  const { eventHandlerForWSIWYG } = useUtilities();
  const { systemCode, innerElements, contentHeading, contentBody } = props;
  const {
    CommonModule: { HorizontalContentPane, VerticalContentPane },
  } = useElementContext();

  const panes = innerElements.map((el, idx) => {
    el.viewMode = "vertical";
    // Change the element to horizontal if it is the last element and the one remainder element
    if (idx === innerElements.length && idx % 4) el.viewMode = "horizontal";
    return el;
  });

  return (
    <div className={`content-grid ${systemCode}`}>
      <div className="container">
        {contentHeading && (
          <div className="info text-center mb-5">
            <h2>{contentHeading}</h2>
            <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
          </div>
        )}
        {array_chunks(panes, 3).map((el, idx) => {
          const verticalPanes = el.filter((child) => child.viewMode === "vertical");
          const horizontalPanes = el.filter((child) => child.viewMode === "horizontal");
          return (
            <div key={idx}>
              <div className="row px-3">
                {verticalPanes.map((pane, idx) => (
                  <VerticalContentPane key={idx} siblingCount={verticalPanes.length} {...pane} />
                ))}
              </div>
              <div className="row px-3">
                {horizontalPanes.map((pane, idx) => (
                  <HorizontalContentPane key={idx} {...pane} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ContentGrid };
