import { useUtilities } from "../../hooks/useUtilities";
import { useElementContext } from "../../contexts";

const ActionBanner = ({
  contentHeading,
  contentSubHeading,
  contentBody = "",
  linkLabel = "",
  linkUrl = "",
  style,
  bootStrapLayoutClass,
  ctaBackgroundImage,
  urlTarget,
}) => {
  const {
    CommonModule: { CallToActionBtn },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  if (!contentHeading) return null;
  return (
    <div
      className={`py-4 action-banner-sec ${style.toLowerCase()} ${bootStrapLayoutClass}`}
      style={ctaBackgroundImage ? { backgroundImage: `url(${ctaBackgroundImage})`, backgroundSize: "cover" } : {}}
    >
      <div className="container">
        <div className="row">
          <div className="col-0 col-md-2" />
          <div className="col-md-8 text-center">
            {!!contentHeading && (
              <header className="section-title">
                <h2>{contentHeading}</h2>
                <h5>{contentSubHeading}</h5>
              </header>
            )}
            <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
            {!!linkLabel && <CallToActionBtn urlTarget={urlTarget} linkLabel={linkLabel} linkUrl={linkUrl} />}
          </div>
          <div className="col-0 col-md-2" />
        </div>
      </div>
    </div>
  );
};

export { ActionBanner };
