import React, { useEffect } from "react";
import { useUtilities } from "../../hooks/useUtilities";
import { useTranslation } from "react-i18next";

import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import { useElementContext } from "../../contexts";

const SlideContainer = ({ linkUrl, linkLabel, children, index, systemCode, slideLayoutMode }) => {
  const {
    CommonModule: { ExternalLink },
  } = useElementContext();
  const classList = ["carousel-item", ...(index === 0 ? ["active"] : []), systemCode, slideLayoutMode].join(" ");
  if (linkUrl && !linkLabel) {
    return (
      <ExternalLink className={classList} linkUrl={linkUrl}>
        {children}
      </ExternalLink>
    );
  }
  return <div className={classList}>{children}</div>;
};

const ContentSlider = ({ innerElements: slides, systemCode, slideLayoutMode, ...parent }) => {
  const { t } = useTranslation();
  let { eventHandlerForWSIWYG } = useUtilities();
  const {
    CommonModule: { AttributeImage, ExternalLink },
  } = useElementContext();

  useEffect(() => {
    if (!!slides?.length) {
      var carousel = new bootstrap.Carousel(document.getElementById("carousel_" + parent?.contentElementID), {
        interval: 5000,
        ride: true,
        pause: "hover",
      });
      carousel.cycle();
    }
    //eslint-disable-next-line
  }, [slides?.length]);
  if (!slides?.length) return null;
  return (
    <div className={`hero content-slider container-fluid mb-4 ${systemCode} px-0`}>
      {!!slides?.length && (
        <div
          id={`carousel_` + parent?.contentElementID}
          className="carousel slide"
          data-bs-ride={`carousel_` + parent?.contentElementID}
        >
          <div className="carousel-indicators">
            {slides?.length > 1 &&
              slides?.map((_, idx) => {
                return (
                  <button
                    key={idx}
                    type="button"
                    data-bs-target={`#carousel_` + parent?.contentElementID}
                    data-bs-slide-to={idx}
                    className={idx === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={`Slide ${idx}`}
                  ></button>
                );
              })}
          </div>
          <div className="carousel-inner">
            {slides?.map(
              ({ systemCode, contentBody, contentElementName, imagePath, linkUrl, linkLabel, withOverlay }, index) => {
                return (
                  <SlideContainer
                    key={index}
                    index={index}
                    systemCode={systemCode}
                    linkUrl={linkUrl}
                    linkLabel={linkLabel}
                    slideLayoutMode={slideLayoutMode}
                  >
                    <div
                      className="carousel-image"
                      style={
                        slideLayoutMode === "Overlay"
                          ? { opacity: 1 }
                          : !(contentBody || contentElementName)
                          ? { opacity: 1 }
                          : {}
                      }
                    >
                      <AttributeImage fileName={imagePath} alt="carouselImage" className="mw-100" />
                    </div>
                    {(contentElementName?.trim()?.length > 0 || contentBody?.trim()?.length > 0) && (
                      <div
                        className={`carousel-caption ${
                          slideLayoutMode === "Overlay" ? "overlay-carousel-caption" : ""
                        }`}
                        style={{ ...(withOverlay ? {} : { backgroundImage: "none" }) }}
                      >
                        <div className="caption-container">
                          <h2>{contentElementName}</h2>
                          <div onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: contentBody }} />
                          {linkLabel?.trim()?.length > 0 && (
                            <ExternalLink
                              className={`btn btn-primary p-2 px-4 ${!linkUrl ? "pe-none" : ""}`}
                              linkUrl={linkUrl}
                            >
                              {linkLabel}
                            </ExternalLink>
                          )}
                        </div>
                      </div>
                    )}
                  </SlideContainer>
                );
              },
            )}
          </div>

          {slides?.length > 1 && (
            <>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target={`#carousel_` + parent?.contentElementID}
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                <span className="visually-hidden">{t("frontend.core.previous")}</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target={`#carousel_` + parent?.contentElementID}
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">{t("frontend.core.next")}</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export { ContentSlider };
