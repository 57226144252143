import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBrand } from "../../hooks";
import { useContentContext } from "../../contexts/ContentContext";

import { BrandContext, IBrandContext } from "./BrandContext";

import { useElementContext } from "../../contexts/ElementContext";

const BrandPage = () => {
  const { t } = useTranslation();
  const pageData = useContentContext();
  const { brandResponse, slug, subHeading, productTypeData, productTypeRequest } = useBrand();
  const {
    ProductListingModule,
    PageModule: { DynamicPage },
    CommonModule: { ListingBanner, PageContentRow, BrandRangePreFilter },
  } = useElementContext();

  const {
    brandSearchListing,
    brandHeaderStyle = "compact",
    brandRangePreFilter,
    showShopByBrand = true,
  } = pageData.templateData || {};
  const [productTypeSlug, setProductTypeSlug] = useState("");

  const { data, isLoaded } = brandResponse as {
    isFetching: boolean;
    isLoaded: boolean;
    makeRequest: boolean;
    data: never[];
    error: string;
    params: {};
    entity: string;
  };

  const {
    brandName,
    images,
    brandDescription,
    settings: { brandHTMLTitleString } = { brandHTMLTitleString: "" },
  } = data?.[0] || {};

  const title = pageData?.title || brandName;
  const pageLogo = pageData?.pageLogo || (images as string[] | undefined)?.at(0);
  const description = pageData?.description || brandDescription;

  const context: IBrandContext = useMemo(
    () => ({ rangeList: { isFetching: !isLoaded, data: productTypeData } }),
    [isLoaded, productTypeData],
  );

  if (!brandSearchListing) return <DynamicPage children={undefined} />;
  const renderBrandListing = () => {
    if (!isLoaded || !data.length) return null;
    if (brandRangePreFilter && !productTypeSlug) return null;
    return (
      <ProductListingModule.CoreComponents.SearchListing
        hide="brand"
        brandSlug={slug}
        {...brandSearchListing}
        initPreFilter={{
          facet_brand: slug,
          facet_productType: productTypeSlug,
        }}
      />
    );
  };

  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <BrandContext.Provider value={context}>
          {brandHTMLTitleString && <Helmet title={brandHTMLTitleString} />}
          <PageContentRow>
            <ListingBanner
              subHeading={subHeading}
              heading={title}
              images={[pageLogo]}
              description={description}
              isCollapsible={brandHeaderStyle === "compact"}
              bannerLink={
                showShopByBrand && (
                  <Link className={`banner-link text-uppercase small pb-2`} to="/brands">
                    {t("frontend.product.shopByBrand")}
                  </Link>
                )
              }
            />
          </PageContentRow>
          {renderDynamicContent()}
          {brandRangePreFilter && (productTypeRequest as { isLoaded: boolean }).isLoaded && (
            <BrandRangePreFilter
              setProductTypeSlug={setProductTypeSlug}
              productTypeData={productTypeData}
              rangeTilesSettings={brandRangePreFilter.rangeTilesSettings || "showOnlyDirectChild"}
            />
          )}
          {renderBrandListing()}
        </BrandContext.Provider>
      )}
    </DynamicPage>
  );
};

export default BrandPage;
