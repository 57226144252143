import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { axios, getSdkURL } from "../../../services";

import { useAccountOverview } from "../../../hooks";
import { useElementContext } from "../../../contexts";

const OverviewDraftOrders = () => {
  const { t } = useTranslation();
  const {
    orders: draftOrder,
    pageRecordsShow,
    currentPage,
    setCurrentPage,
  } = useAccountOverview({
    tradeOrderListing: 1,
  });
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const [orderName, setOrderName] = useState("");
  const navigate = useNavigate();
  const {
    CommonModule: { OverviewOrderList, ListingPagination, Modal },
  } = useElementContext();

  const handleSubmit = () => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/createTradeOrder`,
      data: { orderName },
    }).then((response) => {
      if (response?.status === 200 && response?.data?.tradeOrderID) {
        navigate(`/my-account/draftorder/${response?.data?.tradeOrderID}`);
      }
    });
  };

  const totalPages = Math.ceil(draftOrder?.data?.records / pageRecordsShow);

  return (
    <div className="my-3">
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h4>{t("frontend.order.draft_order")}</h4>
        <button className="btn btn-primary" onClick={() => setCreateOrderModal(true)}>
          Start New Order
        </button>
      </div>

      <OverviewOrderList orders={draftOrder} orderType="Draft" />
      <ListingPagination
        recordsCount={draftOrder?.data?.records}
        currentPage={currentPage}
        totalPages={totalPages}
        setPage={setCurrentPage}
      />
      <Modal show={createOrderModal} setShow={setCreateOrderModal} title="Create Order" size="xMedium">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="text-center"
        >
          <div className="col-12 d-flex mb-2">
            <div className="col-4">
              <label className="form-label">Order Name*</label>
            </div>
            <div className="col-8">
              <input
                type="text"
                className="form-control"
                name="orderName"
                onChange={(e) => setOrderName(e.target.value)}
                value={orderName}
                required
              />
            </div>
          </div>
          <button className="btn btn-primary my-2" type="submit">
            Save
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default OverviewDraftOrders;
