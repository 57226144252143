import { createContext } from "react";
import { useUtilities } from "../../hooks/useUtilities";

import { DynamicFormProps } from "./interface";
import { useFormModel } from "./hooks/useFormModel";
import { formModel } from "./dynamicFormModel.class";
import { useElementContext } from "../../contexts";

const FormContext = createContext<formModel>(null!);

const DynamicForm = (props: DynamicFormProps) => {
  const {
    CommonModule: { SubmitButton, FormInputLayout },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  const model = useFormModel(props);
  const {
    stylingCustomClasses,
    bootStrapLayoutClass,
    heading,
    description,
    successMessage,
    isFormSubmitted,
    handleFormSubmit,
  } = model;

  return (
    <FormContext.Provider value={model}>
      <div className={`px-4 cetForm ${stylingCustomClasses} ${bootStrapLayoutClass}`}>
        <h3 className="text-center">{heading}</h3>
        <div
          className="text-center"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />

        {/* Form post submission success messgae */}
        {isFormSubmitted ? (
          <div className="text-center" dangerouslySetInnerHTML={{ __html: successMessage }} />
        ) : (
          <form onSubmit={handleFormSubmit}>
            <FormInputLayout />
            <SubmitButton />
          </form>
        )}
      </div>
    </FormContext.Provider>
  );
};

export { DynamicForm, FormContext };
