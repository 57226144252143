import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { storefrontPageModel } from "../services/models/storefrontPageModel.class";
import { useServiceContext } from "./ServiceContext";

const ContentContext = createContext();

const ContentContextProvider = ({ children }) => {
  const { pathname } = useLocation("home");
  const routing = useSelector((state) => state.configuration.router);
  const [pageData, setPageData] = useState(new storefrontPageModel());
  const { StorefrontContentService } = useServiceContext();

  useEffect(() => {
    let didCancel = false;
    const urlTitlePath = pathname?.substring(1);
    const [templateUrlTitlePath] = urlTitlePath?.split("/");
    const isRestrictedPath = routing?.find((el) => el.URLKey === templateUrlTitlePath);
    let props = { urlTitlePath };
    const service = new StorefrontContentService();

    if (pathname === "/") {
      props = { urlTitlePath: "home" };
    } else if (isRestrictedPath) {
      props = { ...props, templateUrlTitlePath };
    } else {
      props = { ...props, templateUrlTitlePath: "default" };
    }

    service.fetchPage(props).then((data) => {
      console.log("#### Content Loaded #### ", data.hydrated);
      if (!didCancel && data.hydrated) setPageData(data.hydrated);
    });

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <ContentContext.Provider value={pageData}>{children}</ContentContext.Provider>;
};

const useContentContext = () => {
  // get the context
  const context = useContext(ContentContext);
  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useContentContext was used outside of its Provider");
  }

  return context;
};

const usePageTypeContext = () => {
  // get the context
  const context = useContext(ContentContext);

  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useContentContext was used outside of its Provider");
  }
  if (context.pageType === "8a82877783d65ae30183d741f8580357") return "cptProductTypeListing";
  if (context.pageType === "8a82877783d65ae30183d799509304cb") return "cptBrandListing";
  if (context.pageType === "8a82877783d65ae30183d79a23bf04ce") return "cptCategoryListing";
  if (context.pageType === "8a82877783d65ae30183d79c288104d3") return "cptSearchListing";
  return "cptBasicPage";
};
const useContentBodyContext = () => {
  // get the context
  const context = useContext(ContentContext);

  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useContentContext was used outside of its Provider");
  }

  return context?.contentElements?.filter((child) => child.contentElementTypeCode === "cetBody")?.at(0);
};

/*
 * we return ContentContext because a user may want to bypass the API call if th
 * user got the Content data from another spot and wants to Hydrate Manually.
 */
export { ContentContext, ContentContextProvider, useContentContext, useContentBodyContext, usePageTypeContext };
