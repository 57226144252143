export interface ProductTypeData {
  urlTitle: string;
  systemCode: string;
  imageFile: string;
  parentProductType_productTypeID: string;
  productTypeDescription: string;
  productTypeID: string;
  productTypeIDPath: string;
  productTypeName: string;
  productTypeNamePath: string;
  childProductTypes: ProductTypeData[];
}

export interface ProductTypeListProps {
  data: any;
  showOnlyDirectChild?: boolean;
  isFetching?: boolean;
}

class ProductTypeListModel {
  childProductTypes: any[] = [];
  showOnlyDirectChild: boolean = false;
  isFetching: boolean = false;
  constructor({ props }: { props: ProductTypeListProps }) {
    this.showOnlyDirectChild = !!props.showOnlyDirectChild;
    this.isFetching = !!props.isFetching;
    const { childProductTypes = [] } = props.data || {};
    this.childProductTypes = [...childProductTypes].sort((a: any, b: any) =>
      a.productTypeName > b.productTypeName ? 1 : -1,
    );
  }
}
export { ProductTypeListModel };
