import { createContext, useContext } from "react";

//plugins
import { SpanConfigurator } from "../plugins/SpanConfigurator/SpanConfigurator";
import { BlackhawkCalculator } from "../plugins/Blackhawk/Blackhawk";
import { useElementContext } from "./ElementContext";

const ComponentContext = createContext();

const ComponentContextProvider = ({ children, customComponents = {} }) => {
  const {
    ProductListingModule,
    LocationModule,
    CommonModule: {
      ImageSlider,
      ContentCard,
      ContentLayout,
      ContentSlider,
      BasicSidebar,
      BulkOrder,
      ContentGrid,
      GoogleMap,
      ActionBanner,
      MainNavBar,
      Video,
      MegaMenu,
      MenuItem,
      ContentColumns,
      ContentBlock,
      Block,
      SimpleTabs,
      ListItem,
      Header,
      SignUpForm,
      Footer,
      ListItemWithImage,
      AccountBubble,
      MiniCart,
      LanguagePicker,
      MultiSitePicker,
      Html,
      Row,
      SearchBar,
      Ribbon,
      DynamicForm,
      ReviewsIoWidget,
      BlogListing,
      DynamicProductListing,
      ImageGallery,
      ColumnResource,
      FeatureBlock,
      CollectionSlider,
      Body,
      TabbedProductListing,
      HTMLcontent,
      FeatureBanners,
      Resources,
      ProductReview,
      OverviewDraftOrders,
      OverviewRecentOrders,
      SubscribeForm,
      BrandRangeList,
      EventListing,
    },
  } = useElementContext();

  const coreComponents = {
    cetImageSlider: ImageSlider,
    cetContentCard: ContentCard,
    cetContentLayout: ContentLayout,
    cetContentSlider: ContentSlider,
    cetSidebar: BasicSidebar,
    cetBulkOrderListing: BulkOrder,
    cetSearchListing: ProductListingModule.CoreComponents.SearchListing,
    cetContentGrid: ContentGrid,
    cetMap: GoogleMap,
    cetCallToCaction: ActionBanner,
    cetMenu: MainNavBar,
    cetVideo: Video,
    cetMegaMenu: MegaMenu,
    cetMenuItem: MenuItem,
    cetColumns: ContentColumns,
    cetColumn: ContentBlock,
    cetBlock: Block,
    cetTabs: SimpleTabs,
    cetListItem: ListItem,
    cetHeader: Header,
    cetMailChimpForm: SignUpForm,
    cetFooter: Footer,
    cetListItemWithImage: ListItemWithImage,
    cetAccountLink: AccountBubble,
    cetCartLink: MiniCart,
    cetLanguageSelector: LanguagePicker,
    cetSiteSelector: MultiSitePicker,
    cetHTML: Html,
    cetHtml: Html,
    cetRow: Row,
    cetLink: "cetLink",
    cetSearchBox: SearchBar,
    cetRibbon: Ribbon,
    cetForm: DynamicForm,
    cetReviewsioBlock: ReviewsIoWidget,
    cetBlogListing: BlogListing,
    cetProductListing: DynamicProductListing,
    cetImageGallery: ImageGallery,
    cetColumnResource: ColumnResource,
    cetFeatureBlock: FeatureBlock,
    cetCollectionListing: CollectionSlider,
    cetWhereToBuy: LocationModule.CoreComponents.WhereToBuyLocation,
    cetBody: Body,
    cetTabbedProductListing: TabbedProductListing,
    cetHTMLcontent: HTMLcontent,
    cetFeatureBanners: FeatureBanners,
    cetBlackhawkCalculator: BlackhawkCalculator,
    cetSpanConfigurator: SpanConfigurator,
    cetResources: Resources,
    cetPreFilters: ProductListingModule.CoreControl.SearchPreFilter,
    cetProductReview: ProductReview,
    cetAccountDraftOrders: OverviewDraftOrders,
    cetAccountRecentOrders: OverviewRecentOrders,
    cetSubscribeForm: SubscribeForm,
    cetBrandRangeList: BrandRangeList,
    cetEventListing: EventListing,
  };
  return (
    <ComponentContext.Provider value={{ ...coreComponents, ...customComponents }}>{children}</ComponentContext.Provider>
  );
};

const useComponentContext = () => {
  // get the context
  const context = useContext(ComponentContext);

  // if `undefined`, throw an error
  if (!context === undefined) {
    throw new Error("useComponentContext was used outside of its Provider");
  }

  return context;
};

/*
 * we return ComponentContext because a user may want to bypass the API call if th
 * user got the Content data from another spot and wants to Hydrate Manually.
 */
export { ComponentContext, ComponentContextProvider, useComponentContext };
