import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../actions";
import { useState } from "react";

const LogoutSpecialMenu = ({ color = "", cssClass = "", linkLabel = "Logout" }) => {
  const user = useSelector((state: any) => state.userReducer);
  const { accountID } = user;
  const dispatch = useDispatch();
  const [disableButton, setdisableButton] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!accountID) {
    return null;
  }

  return (
    <span
      className={`nav-item link-button d-block user-logout p-2 fw-bold ${cssClass}`}
      style={{ color: color }}
      onClick={async () => {
        if (disableButton) return;
        setdisableButton(true);
        await dispatch<any>(logout(t("frontend.account.logout_success"), t("frontend.account.logout_failure")));
        navigate("/");
      }}
    >
      {linkLabel}
    </span>
  );
};

export { LogoutSpecialMenu };
