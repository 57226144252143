import { FilterSpec } from "../../Controls/FilterBanner";
import { Location } from "../../../../interface/Location";
import { WhereToBuyLocationModel } from "./WhereToBuyLocation.componentModel";
import { useElementContext } from "../../../../contexts";

export const WhereToBuyLocationView = ({
  componentData,
  setSearchValue,
  updateFiltersAndLocations,
  onLocationClicked,
}: {
  componentData: WhereToBuyLocationModel;
  setSearchValue: (value: string) => void;
  updateFiltersAndLocations: (key: string, selection: string, searchText?: string) => void;
  onLocationClicked: (addressLatitude: string, addresslongitude: string) => void;
}) => {
  if (componentData.wtbLayoutType === "slimRow") {
    return (
      <WhereToBuyLocationSlim
        {...componentData}
        setSearchValue={setSearchValue}
        updateFiltersAndLocations={updateFiltersAndLocations}
        onLocationClicked={onLocationClicked}
      />
    );
  } else {
    return (
      <WhereToBuyLocationFull
        {...componentData}
        setSearchValue={setSearchValue}
        updateFiltersAndLocations={updateFiltersAndLocations}
        onLocationClicked={onLocationClicked}
      />
    );
  }
};

interface ViewProps {
  displayedRetailLocations: Location[];
  updateFiltersAndLocations: (key: string, selection: string, searchText?: string) => void;
  filterStack: FilterSpec[];
  mapCenter?: { AddressLatitude: string; AddressLongitude: string };
  defaultZoom?: number;
  searchValue: string;
  setSearchValue: (value: string) => void;
  showSearch: boolean;
  title: string;
  onLocationClicked: (addressLatitude: string, addresslongitude: string) => void;
}

const WhereToBuyLocationFull = ({
  displayedRetailLocations,
  updateFiltersAndLocations,
  filterStack,
  mapCenter,
  defaultZoom,
  searchValue,
  setSearchValue,
  showSearch,
  title,
  onLocationClicked,
}: ViewProps) => {
  const { LocationModule } = useElementContext();
  const hasResults = displayedRetailLocations.filter((loc) => loc.active).length > 0;
  return (
    <div className="container">
      {title ? <h2 className="text-center">{title}</h2> : null}
      <div className="container-fluid">
        <div className="grid py-3">
          {(showSearch || filterStack) && (
            <div className="g-col-12 g-col-md-4 g-col-lg-3">
              <LocationModule.CoreControl.FilterBanner
                updateFiltersAndLocations={updateFiltersAndLocations}
                data={displayedRetailLocations}
                filters={filterStack}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                showSearch={showSearch}
              />
            </div>
          )}
          <div
            className={`h-100 ${showSearch || filterStack ? "g-col-12 g-col-md-8 g-col-lg-9" : "g-col-12"}`}
            style={{ minHeight: 500 }}
          >
            <LocationModule.CoreControl.LocationMap
              center={
                mapCenter?.AddressLatitude && mapCenter?.AddressLongitude
                  ? {
                      lat: parseFloat(mapCenter.AddressLatitude),
                      lng: parseFloat(mapCenter.AddressLongitude),
                    }
                  : undefined
              }
              zoom={defaultZoom}
              locations={displayedRetailLocations
                ?.filter((loc) => loc.active)
                .map((location) => ({
                  lat: parseFloat(location.AddressLatitude),
                  lng: parseFloat(location.AddressLongitude),
                  title: location.CustomerName,
                  address: location.PhysicalAddress1,
                  phoneNo: location.PhoneNo,
                }))}
            />
          </div>
        </div>
      </div>
      {!hasResults && (
        <p className="text-center bg-white rounded p-2">
          No locations were found with the given criteria. Please modify your selections or input.
        </p>
      )}

      <div className="grid py-5">
        {displayedRetailLocations
          ?.filter((loc) => loc.active)
          .map((loc) => {
            return (
              <div key={loc.CustomerName} className="g-col-12 g-col-md-4">
                <LocationModule.CoreControl.WhereToBuyLocationCard onLocationClicked={onLocationClicked} {...loc} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const WhereToBuyLocationSlim = ({
  displayedRetailLocations,
  updateFiltersAndLocations,
  filterStack,
  mapCenter,
  defaultZoom,
  searchValue,
  setSearchValue,
  showSearch,
  title,
  onLocationClicked,
}: ViewProps) => {
  const { LocationModule } = useElementContext();
  const hasResults = displayedRetailLocations.filter((loc) => loc.active).length > 0;
  return (
    <div className="WhereToBuyLocationSlim p-3">
      {title ? <h2 className="text-center">{title}</h2> : null}
      <div className="container-fluid">
        {(showSearch || filterStack) && (
          <div className=" py-3">
            <LocationModule.CoreControl.FilterBanner
              isSlim
              updateFiltersAndLocations={updateFiltersAndLocations}
              data={displayedRetailLocations}
              filters={filterStack}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              showSearch={showSearch}
            />
          </div>
        )}
        <div className="grid py-3">
          <div className="g-col-12 g-col-md-8 h-100" style={{ minHeight: 500 }}>
            <LocationModule.CoreControl.LocationMap
              center={
                mapCenter?.AddressLatitude && mapCenter?.AddressLongitude
                  ? {
                      lat: parseFloat(mapCenter.AddressLatitude),
                      lng: parseFloat(mapCenter.AddressLongitude),
                    }
                  : undefined
              }
              zoom={defaultZoom}
              locations={displayedRetailLocations
                ?.filter((loc) => loc.active)
                .map((location) => ({
                  lat: parseFloat(location.AddressLatitude),
                  lng: parseFloat(location.AddressLongitude),
                  title: location.CustomerName,
                  address: location.PhysicalAddress1,
                  phoneNo: location.PhoneNo,
                }))}
            />
          </div>
          <div className="g-col-12 g-col-md-4">
            {!hasResults && (
              <p className="text-center bg-white rounded p-2">
                No locations were found with the given criteria. Please modify your selections or input.
              </p>
            )}

            <div className="grid pe-3" style={{ maxHeight: "500px", overflowY: "auto" }}>
              {displayedRetailLocations
                ?.filter((loc) => loc.active)
                .map((loc) => {
                  return (
                    <div key={loc.CustomerName} className="g-col-12">
                      <LocationModule.CoreControl.WhereToBuyLocationCard
                        onLocationClicked={onLocationClicked}
                        {...loc}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
