import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { FooterModel, FooterProps } from "./Footer.componentModel";

export const useComponentData = ({ preFooter, children }: FooterProps) => {
  const theme = useSelector((state: any) => state.configuration.site.theme);
  const contentStack = children?.filter(
    (child) => !["cetRibbon", "cetCopyright"].includes(child.props.el.contentElementTypeCode),
  );

  const ribbonMenu = children?.filter((child) => ["cetRibbon"].includes(child.props.el.contentElementTypeCode))[0];
  let formElement: ReactElement | null | undefined = children?.find((child) =>
    ["cetForm"].includes(child.props.el.contentElementTypeCode),
  );
  if (!formElement?.props?.el?.formQuestions[0]) formElement = null;

  const logo =
    theme?.footerLogoTarget === "Alternate"
      ? theme.logo
      : theme?.footerLogoTarget === "None"
      ? ""
      : theme.additionalLogo;

  return { componentData: new FooterModel({ preFooter, ribbonMenu, logo, theme, contentStack, formElement }) };
};
