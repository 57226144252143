import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useProductType } from "../../hooks";
import { useContentContext } from "../../contexts/ContentContext";
import { useElementContext } from "../../contexts/ElementContext";

const ProductType = () => {
  const {
    ProductListingModule,
    ProductModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow, ListingBanner },
  } = useElementContext();
  const { id } = useParams();

  const { productTypeRequest, productTypeData, productTypeListRequest, isError, errorMessage, crumbCalculator } =
    useProductType();
  const pageData = useContentContext();

  const {
    productTypeSearchListing,
    productListingSettings,
    rangeTilesSettings,
    rangeHeaderStyle = "compact",
  } = pageData.templateData || {};
  if (!productTypeSearchListing) return <DynamicPage />;

  const { setting: { productHTMLTitleString } = {} } = productTypeRequest.data || {};

  const title = pageData?.title || productTypeData?.productTypeName;
  const pageLogo = pageData?.pageLogo || productTypeData?.imageFile;
  const description = pageData?.description || productTypeData?.productTypeDescription;

  return (
    <DynamicPage>
      {({ renderDynamicContent }) => (
        <>
          {productHTMLTitleString && <Helmet title={productHTMLTitleString} />}
          <PageContentRow>
            <ListingBanner
              crumbs={crumbCalculator()}
              heading={title}
              images={[pageLogo]}
              description={description}
              type="productType"
              isCollapsible={rangeHeaderStyle === "compact"}
            />
          </PageContentRow>

          {isError && (
            <div className="container bg-light box-shadow-lg rounded-lg p-5">
              <div className="row">
                <div className="alert alert-info" role="alert">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}

          {renderDynamicContent()}

          {rangeTilesSettings !== "hideRangeTiles" && (
            <ProductModule.CoreComponents.ProductTypeList
              showOnlyDirectChild={rangeTilesSettings === "showOnlyDirectChild"}
              isFetching={productTypeListRequest.isFetching || !productTypeRequest.isLoaded}
              data={productTypeData}
            />
          )}

          {(productListingSettings !== "hideProductListing" || productTypeData.childProductTypes?.length === 0) && (
            <ProductListingModule.CoreComponents.SearchListing
              hide="productType"
              initPreFilter={{
                facet_productType: id,
                ...(productListingSettings === "showOnlyParentProducts" ? { includeParentProductsOnly: true } : {}),
              }}
              {...productTypeSearchListing}
            />
          )}
        </>
      )}
    </DynamicPage>
  );
};

export default ProductType;
