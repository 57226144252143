const FormCheckboxGroupField = ({
  attributeName,
  attributeCode,
  requiredFlag,
  options,
  setValue,
  value,
  invalid,
  validationMessage,
}) => {
  return (
    <fieldset>
      <div className={`form-label ${requiredFlag ? "required" : ""}`}>{options.length > 1 ? attributeName : ""}</div>
      {options.map((option, idx) => {
        return (
          <div className="form-check" key={idx}>
            <input
              onChange={() => {
                setValue((response) => {
                  let val;
                  if (response[attributeCode]?.includes(option.value)) {
                    val = response[attributeCode].filter((i) => i !== option.value);
                    if (val.length === 0)
                      // prevent false negative on required field validation
                      val = undefined;
                  } else {
                    // attempt to push the option to the response
                    const pushRes = response[attributeCode]?.push(option.value);
                    // if the push was unsuccessful, create a new array and set val
                    if (!pushRes) val = option.value.toString();
                    // otherwise val is just the response
                    else val = response[attributeCode];
                  }
                  return {
                    ...response,
                    [attributeCode]: val,
                  };
                });
              }}
              value={attributeCode}
              className={"form-check-input" + (invalid ? " is-invalid" : "")}
              type="checkbox"
              name={attributeCode + option.name}
              checked={value?.includes(option.value)}
              id={attributeCode + option.name}
            />
            <label className="form-check-label" htmlFor={attributeCode + option.name}>
              {option.name}
            </label>
          </div>
        );
      })}
      {invalid && <div className="invalid-feedback">{validationMessage ?? ""}</div>}
    </fieldset>
  );
};

export default FormCheckboxGroupField;
