import { PropsWithChildren } from "react";

const MainNavBar = ({ children }: PropsWithChildren) => {
  return (
    <div className="nav-children mb-lg-0 order-xl-3">
      <ul className="nav justify-content-center justify-content-lg-end align-items-center">{children}</ul>
    </div>
  );
};

export { MainNavBar };
