import React, { useState } from "react";
import { useUtilities } from "../../../hooks/useUtilities";

const TabHeading = ({
  contentHeading = "",
  contentElementID,
  isActive = false,
  setActiveTab,
  backgroundColor,
  textColor,
}) => {
  return (
    <li
      className="nav-item"
      role="presentation"
      ref={(node) => {
        if (node?.classList.contains("active")) {
          node?.style.setProperty("background", backgroundColor, "important");
          node?.style.setProperty("border-color", backgroundColor, "important");
        } else {
          node?.style.setProperty("background", "#f8f9fa", "important");
          node?.style.setProperty("border-color", "#f8f9fa", "important");
        }
      }}
    >
      <button
        className={`nav-link ${isActive ? "active" : ""}`}
        ref={(node) => {
          if (node?.classList.contains("active")) {
            node?.style.setProperty("background-color", backgroundColor, "important");
            node?.style.setProperty("border-color", backgroundColor, "important");
            node?.style.setProperty("color", textColor, "important");
          } else {
            node?.style.setProperty("background-color", "#f8f9fa", "important");
            node?.style.setProperty("border-color", "#f8f9fa", "important");
            node?.style.setProperty("color", "#333333", "important");
          }
        }}
        id={`${contentHeading?.replace(/\s/g, "")}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#${contentHeading?.replace(/\s/g, "")}`}
        type="button"
        role="tab"
        aria-controls={`${contentHeading?.replace(/\s/g, "")}`}
        aria-selected="true"
        onClick={(e) => {
          setActiveTab(contentElementID);
          e.preventDefault();
        }}
      >
        {contentHeading}
      </button>
    </li>
  );
};

const TabPanel = (props) => {
  const { contentHeading, contentBody, isActive, parent, displayChildren, child } = props;
  let { eventHandlerForWSIWYG } = useUtilities();
  return (
    <div
      className={`tab-pane fade ${isActive ? "show active" : ""}`}
      id={contentHeading?.replace(/\s/g, "")}
      role="tabpanel"
      aria-labelledby={`${contentHeading?.replace(/\s/g, "")}-tab`}
    >
      {!displayChildren && (
        <>
          <div
            className="content-body"
            onClick={eventHandlerForWSIWYG}
            dangerouslySetInnerHTML={{
              __html: contentBody || "",
            }}
          />
          <div className="tab-panel-children">
            {props?.innerElements
              ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
              ?.map((el, idx) => {
                return React.createElement(parent.__DynamicComponent, { el: el, key: idx });
              })}
          </div>
        </>
      )}

      {displayChildren && (
        <>
          <div className="tab-panel-children">{child}</div>
        </>
      )}
    </div>
  );
};

const AccordionTab = (props) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  let {
    isActive = false,
    parentContentElementID,
    contentElementID,
    setActiveTab,
    contentHeading,
    contentBody,
    parent,
    displayChildren,
    child,
    contentSubHeading,
  } = props;
  return (
    <div className="accordion-item">
      <h2
        className="accordion-header pt-0"
        id={contentElementID}
        onClick={(e) => {
          e.preventDefault();
          if (isActive) setActiveTab(null);
          if (!isActive) setActiveTab(contentElementID);
        }}
      >
        <button
          className={`accordion-button ${isActive ? "" : "collapsed"}`}
          style={
            isActive
              ? {
                  backgroundColor: displayChildren ? parent.tabBgColor : parent.backgroundColor,
                  color: displayChildren ? parent.tabTextColor : parent.textColor,
                }
              : {}
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${contentElementID}`}
          aria-expanded={isActive}
          aria-controls={`#collapse-${contentElementID}`}
        >
          {contentHeading}
        </button>
      </h2>
      <div
        id={`#collapse-${contentElementID}`}
        className={`accordion-collapse  collapse ${isActive ? "show" : ""}`}
        aria-labelledby={contentElementID}
        data-bs-parent={`#${parentContentElementID}`}
      >
        {!displayChildren && (
          <>
            <div className="accordion-body">
              {contentSubHeading?.trim()?.length > 0 && <h4>{contentSubHeading}</h4>}
              <div
                onClick={eventHandlerForWSIWYG}
                dangerouslySetInnerHTML={{
                  __html: contentBody || "",
                }}
              />
              <div className="tab-panel-children">
                {props?.innerElements
                  ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                  ?.map((el, idx) => {
                    return React.createElement(parent.__DynamicComponent, { el: el, key: idx });
                  })}
              </div>
            </div>
          </>
        )}
        {displayChildren && (
          <>
            <div className="tab-panel-children">{child}</div>
          </>
        )}
      </div>
    </div>
  );
};

const AccordionTabs = (props) => {
  const {
    innerElements,
    contentElementID,
    contentBody = "",
    contentHeading = "",
    contentSubHeading = "",
    displayChildren,
    children,
    ...rest
  } = props;
  let { eventHandlerForWSIWYG } = useUtilities();
  const [activeTab, setActiveTab] = useState(innerElements?.at(0)?.contentElementID);
  return (
    <div className="accordion container my-4" id={contentElementID}>
      {contentHeading?.trim()?.length > 0 && <h2>{contentHeading}</h2>}
      {contentSubHeading?.trim()?.length > 0 && <p className="text-muted">{contentSubHeading}</p>}
      {contentBody?.trim()?.length > 0 && (
        <div
          className="tabs-body"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: contentBody,
          }}
        />
      )}

      {!displayChildren &&
        innerElements
          ?.filter((el) => el?.contentElementTypeCode === "cetTab")
          ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
          ?.map((childTab, index) => {
            return (
              <AccordionTab
                parent={rest}
                key={childTab.contentElementID}
                isActive={childTab.contentElementID === activeTab}
                setActiveTab={setActiveTab}
                loc={index}
                {...childTab}
              />
            );
          })}

      {displayChildren &&
        innerElements
          ?.filter((el) => el?.contentElementTypeCode === "cetProductListing")
          ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
          ?.map((childTab, index) => {
            return (
              <AccordionTab
                child={children[index]}
                contentHeading={childTab.contentElementName}
                parent={rest}
                key={childTab.contentElementID?.replace(" ", "-")}
                isActive={childTab.contentElementID.replace(" ", "-") === activeTab}
                setActiveTab={setActiveTab}
                loc={index}
                {...childTab}
                displayChildren
              />
            );
          })}
    </div>
  );
};

const VerticalTabs = ({
  innerElements = [],
  contentElementID,
  contentBody = "",
  contentHeading = "",
  contentSubHeading = "",
  backgroundColor,
  textColor,
  displayChildren,
  tabBgColor,
  tabTextColor,
  children,
  ...rest
}) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const [activeTab, setActiveTab] = useState(innerElements?.at(0)?.contentElementID);
  return (
    <>
      {contentHeading?.trim()?.length > 0 && <h2>{contentHeading}</h2>}
      {contentSubHeading?.trim()?.length > 0 && <h4>{contentSubHeading}</h4>}
      {contentBody?.trim()?.length > 0 && (
        <div
          className="tabs-body"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: contentBody,
          }}
        />
      )}

      {displayChildren && (
        <>
          <div className="d-flex align-items-start verticalTabs shadow flex-wrap flex-md-nowrap">
            <div
              className="nav flex-md-column nav-pills nav-justified"
              id={`v-pills-tab-${contentElementID}`}
              role="tablist"
              aria-orientation="vertical"
            >
              {innerElements
                ?.filter((el) => el?.contentElementTypeCode === "cetProductListing")
                ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                ?.map((childTab) => {
                  return (
                    <TabHeading
                      {...childTab}
                      contentHeading={childTab.contentElementName}
                      key={childTab.contentElementID}
                      isActive={childTab.contentElementID === activeTab}
                      setActiveTab={setActiveTab}
                      backgroundColor={tabBgColor}
                      textColor={tabTextColor}
                    />
                  );
                })}
            </div>
            <div
              className="tab-content"
              style={tabBgColor ? { borderColor: tabBgColor } : undefined}
              id={`v-pills-tabContent-${contentElementID}`}
            >
              {innerElements
                ?.filter((el) => el?.contentElementTypeCode === "cetProductListing")
                ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                ?.map((childTab, idx) => {
                  return (
                    <TabPanel
                      parent={rest}
                      {...childTab}
                      child={children[idx]}
                      contentHeading={childTab.contentElementName}
                      key={childTab.contentElementID}
                      isActive={childTab.contentElementID === activeTab}
                      setActiveTab={setActiveTab}
                      displayChildren
                    />
                  );
                })}
            </div>
          </div>
        </>
      )}
      {!displayChildren && (
        <>
          <div className="d-flex align-items-start verticalTabs shadow flex-wrap flex-md-nowrap container">
            <div
              className="nav flex-md-column nav-pills nav-justified"
              id={`v-pills-tab-${contentElementID}`}
              role="tablist"
              aria-orientation="vertical"
            >
              {innerElements
                ?.filter((el) => el?.contentElementTypeCode === "cetTab")
                ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                ?.map((childTab) => {
                  return (
                    <TabHeading
                      {...childTab}
                      key={childTab.contentElementID}
                      isActive={childTab.contentElementID === activeTab}
                      setActiveTab={setActiveTab}
                      backgroundColor={backgroundColor}
                      textColor={textColor}
                    />
                  );
                })}
            </div>
            <div
              className="tab-content"
              style={tabBgColor ? { borderColor: tabBgColor } : undefined}
              id={`v-pills-tabContent-${contentElementID}`}
            >
              {innerElements
                ?.filter((el) => el?.contentElementTypeCode === "cetTab")
                ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
                ?.map((childTab) => {
                  return (
                    <TabPanel
                      parent={rest}
                      {...childTab}
                      key={childTab.contentElementID}
                      isActive={childTab.contentElementID === activeTab}
                      setActiveTab={setActiveTab}
                    />
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const HorizontalTabs = ({
  innerElements = [],
  contentElementID,
  contentBody = "",
  contentHeading = "",
  contentSubHeading = "",
  backgroundColor,
  textColor,
  displayChildren,
  tabBgColor,
  tabTextColor,
  children,
  ...rest
}) => {
  let { eventHandlerForWSIWYG } = useUtilities();
  const [activeTab, setActiveTab] = useState(innerElements?.at(0)?.contentElementID);
  return (
    <div className="Tabs shadow container">
      {contentHeading?.trim()?.length > 0 && <h2>{contentHeading}</h2>}
      {contentSubHeading?.trim()?.length > 0 && <h4>{contentSubHeading}</h4>}
      {contentBody?.trim()?.length > 0 && (
        <div
          className="tabs-body"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{
            __html: contentBody,
          }}
        />
      )}

      {displayChildren && (
        <>
          <ul
            className="nav nav-tabs nav-justified border-0"
            id={`horizontalTabsHeading-${contentElementID}`}
            role="tablist"
          >
            {innerElements
              ?.filter((el) => el?.contentElementTypeCode === "cetProductListing")
              ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
              ?.map((childTab) => {
                return (
                  <TabHeading
                    {...childTab}
                    contentHeading={childTab.contentElementName}
                    key={childTab.contentElementID}
                    isActive={childTab.contentElementID === activeTab}
                    setActiveTab={setActiveTab}
                    backgroundColor={tabBgColor}
                    textColor={tabTextColor}
                  />
                );
              })}
          </ul>
          <div
            className="tab-content vertical-tab-border p-2 p-md-5"
            style={tabBgColor ? { borderColor: tabBgColor } : undefined}
            id={`horizontalTabsPanels-${contentElementID}`}
          >
            {innerElements
              ?.filter((el) => el?.contentElementTypeCode === "cetProductListing")
              ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
              ?.map((childTab, idx) => {
                return (
                  <TabPanel
                    parent={rest}
                    {...childTab}
                    child={children[idx]}
                    contentHeading={childTab.contentElementName}
                    key={childTab.contentElementID}
                    isActive={childTab.contentElementID === activeTab}
                    setActiveTab={setActiveTab}
                    displayChildren
                  />
                );
              })}
          </div>
        </>
      )}
      {!displayChildren && (
        <>
          <ul
            className="nav nav-tabs nav-justified border-0"
            id={`horizontalTabsHeading-${contentElementID}`}
            role="tablist"
          >
            {innerElements
              ?.filter((el) => el?.contentElementTypeCode === "cetTab")
              ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
              ?.map((childTab) => {
                return (
                  <TabHeading
                    {...childTab}
                    key={childTab.contentElementID}
                    isActive={childTab.contentElementID === activeTab}
                    setActiveTab={setActiveTab}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  />
                );
              })}
          </ul>
          <div
            className="tab-content vertical-tab-border p-2 p-md-5"
            style={tabBgColor ? { borderColor: tabBgColor } : undefined}
            id={`horizontalTabsPanels-${contentElementID}`}
          >
            {innerElements
              ?.filter((el) => el?.contentElementTypeCode === "cetTab")
              ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
              ?.map((childTab) => {
                return (
                  <TabPanel
                    parent={rest}
                    {...childTab}
                    key={childTab.contentElementID}
                    isActive={childTab.contentElementID === activeTab}
                    setActiveTab={setActiveTab}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

const SimpleTabs = (props) => {
  const { tabViewMode = "horizontalTabs", ...rest } = props;
  if (tabViewMode === "accordion") return <AccordionTabs {...rest} />; //accordion
  if (tabViewMode === "verticalTabs") return <VerticalTabs {...rest} />; // horizontalTabs
  if (tabViewMode === "horizontalTabs") return <HorizontalTabs {...rest} />; //verticalTabs

  return null;
};

export { TabHeading, TabPanel, SimpleTabs };
