import { useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import axios, { CancelTokenSource } from "axios";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { useServiceContext } from "../../../contexts";
import { SearchBarProps, SearchBarModel } from "./SearchBar.componentModel";
import { getRouteMapping } from "../../../selectors";

export const useComponentData = (props: SearchBarProps) => {
  const { redirectToSearchPage = false, searchBoxPlaceholder = "", searchBoxTypeaheadFlag = false } = props;
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);
  const routeMapping = useSelector(getRouteMapping);
  const navigate = useNavigate();

  const location = useLocation();
  const { keyword } = queryString.parse(location.search);
  const [searched, setSearch] = useState<any>(null);

  const [searchTerm, setSearchTerm] = useState((keyword as string) || "");
  let [opened, _setOpened] = useState(false);

  const serachRef = useRef<CancelTokenSource>();

  useDebounce(
    () => {
      if (opened && searchTerm) predictiveSearch(searchTerm);
    },
    500,
    [searchTerm, opened],
  );

  const setOpened = (val: boolean) => searchBoxTypeaheadFlag && _setOpened(val);

  const makeSearch = (searchValue: string) => {
    if (redirectToSearchPage) {
      navigate({
        pathname: "/shop",
        search: queryString.stringify({ keyword: searchValue }, { arrayFormat: "comma" }),
      });
      return;
    }
    navigate({
      search: queryString.stringify(
        { ...queryString.parse(location.search), keyword: searchValue },
        { arrayFormat: "comma" },
      ),
    });
    setSearchTerm("");
  };

  const predictiveSearch = (value: string) => {
    if (serachRef.current) serachRef.current.cancel();

    serachRef.current = axios.CancelToken.source();
    if (value === "") {
      // setHide(false);
      setSearch(null);
      return null;
    }

    productService.searchTypeahead(value, "product", { source: serachRef.current }).then(({ data }) => {
      setSearch(data);
    });
  };

  return {
    componentData: new SearchBarModel({ opened, searchBoxPlaceholder, searchTerm, searched, routeMapping }),
    componentService: { setOpened, makeSearch, setSearchTerm },
  };
};
